// import React from 'react';
// import { ResponsiveChoroplethCanvas } from '@nivo/geo';
// import worldGeoJson from '../GeoChart/data/worldGeoJson.json';

// const Choropleth = ({ data }) => {
//   return (
//     <ResponsiveChoroplethCanvas
//       data={data}
//       features={worldGeoJson.features}
//       margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
//       colors={'YlGn'}
//       unknownColor="#cdcdcd"
//       domain={[0, 1000]}
//       label="properties.name"
//       valueFormat=".2s"
//       projectionScale={100}
//       projectionTranslation={[0.5, 0.5]}
//       projectionRotation={[0, 0, 0]}
//       enableGraticule={false}
//       borderWidth={0.2}
//       borderColor="#252525"
//       legends={[
//         {
//           anchor: 'bottom-left',
//           direction: 'column',
//           justify: true,
//           translateX: 10,
//           translateY: -20,
//           itemsSpacing: 0,
//           itemWidth: 80,
//           itemHeight: 14,
//           itemDirection: 'left-to-right',
//           itemTextColor: '#444444',
//           itemOpacity: 0.85,
//           symbolSize: 10,
//           effects: [
//             {
//               on: 'hover',
//               style: {
//                 itemTextColor: '#000000',
//                 itemOpacity: 1,
//               },
//             },
//           ],
//         },
//       ]}
//     />
//   );
// };

// export default Choropleth;

import React, { useState } from 'react';
import { ResponsiveChoroplethCanvas } from '@nivo/geo';
import worldGeoJson from '../GeoChart/data/worldGeoJson.json';

const Choropleth = ({ data }) => {
  const [zoomScale, setZoomScale] = useState(100); // Initial zoom scale
  const [translation, setTranslation] = useState([0.5, 0.5]); // Initial translation

  const handleClick = (feature) => {
    // Zoom in by increasing the scale and adjusting the translation to focus on the clicked country
    const { properties, geometry } = feature;
    const [minLng, minLat, maxLng, maxLat] = geometry.bbox;

    // Calculate center of the country
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;

    // Set zoom level and translate the projection to the country's center
    setZoomScale(300); // Increase scale to zoom in
    setTranslation([
      0.5 - centerLng / 360, // Adjust x translation (longitude)
      0.5 - centerLat / 180, // Adjust y translation (latitude)
    ]);
  };

  return (
    <ResponsiveChoroplethCanvas
      data={data}
      features={worldGeoJson.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={'YlGn'}
      unknownColor="#cdcdcd"
      domain={[0, 1000]}
      label="properties.name"
      valueFormat=".2s"
      projectionScale={zoomScale} // Use the dynamic scale
      projectionTranslation={translation} // Use the dynamic translation
      projectionRotation={[0, 0, 0]}
      enableGraticule={false}
      borderWidth={0.2}
      borderColor="#252525"
      onClick={handleClick} // Add the click handler
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: true,
          translateX: 10,
          translateY: -20,
          itemsSpacing: 0,
          itemWidth: 80,
          itemHeight: 14,
          itemDirection: 'left-to-right',
          itemTextColor: '#444444',
          itemOpacity: 0.85,
          symbolSize: 10,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000000',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default Choropleth;
