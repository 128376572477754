import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get, patch, post } from '../apiCalls';

export const fetchOpenBenefitsAPI = async (subType, pageIndex) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/open-for-enrollment?&subType=${subType}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const employeeBenefitsDashboardAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/dashboard/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const recentClaimsAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const allBenefitsAPI = async (
  id,
  status,
  pageIndex,
  search,
  programId,
) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_BENEFITS_V2
      }/benefit/${id}?status=${status}&s=${search}${
        programId ? `&programId=${programId}` : ''
      }`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const benefitEligibilityDataAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/eligibility-data/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const benfitOpenCloseStatus = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/open/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const benefitEmployeeDetailAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/get-employee-benefit-detail/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const createEnrolmentAPI = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/create-employee-benefit-detail`,
      body,
      'multipart/form-data',
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchEmployeeEnrolmentAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/get-all-employee-benefit-enrollment?id=${id}&pageSize=1&pageIndex=0`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEmployeeEnrolmentDetailsAPI = async (benefitId, userId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/get-employee-benefit-enrollment-for-edit?benefitId=${benefitId}&userId=${userId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const unEnrollEmployeeEnrolmentAPI = async (id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/delete-employee-benefit-enrollment/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateEnrolmentAPI = async (body, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/update-employee-benefit-enrollment/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchEmployeeEnrolmentDetails = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/get-employee-benefit-enrollment/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const saveEmployeeClaimByAdminAPI = async (body, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/admin/save/${id}`,
      body,
      'multipart/form-data',
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const saveEmployeeClaimAPI = async (body, type, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/save/${id}`,
      body,
      type,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEmployeeClaimAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/get-draft-claim/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getClaimDraftsAPI = async (type) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/drafts?type=${type}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getClaimFormById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim-form/get-benefit-claim-form/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEnrolmentFormById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment-form/get-benefit-enrollment-form/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEnrolmentFormByIdAdmin = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/admin/get-benefit-enrollment-form/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getClaimFormByIdAdmin = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/admin/get-benefit-claim-form/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const createEnrolmentByAdminAPI = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/admin/enroll`,
      body,
      'multipart/form-data',
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const createEmployeeClaimAPI = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/${id}`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const deleteDraftAPI = async (id, type) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/delete-draft/${id}?type=${type}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchEmployeeClaimDetails = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/get-employee-benefit-claim-detail/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchClaimHistoryAPI = async (id, pageIndex) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/claim/${id}?pageIndex=${pageIndex}&pageSize=10`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const checkEnrolmentMaxTimeAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/check-enrollment-max-time?benefitId=${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const checkClaimMaxTimeAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee/check-claim-max-time?benefitId=${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const initiateEnrolmentWorkflowAPI = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-benefit/initiate-benefit-enrollment-workflow/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchSubBenefitsAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/get-sub-benefit/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchDependents = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/dependents`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

// create life Event

export const createLifeEvent = async (body, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/life-event/create-life-event`,
      body,
      type,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchLifeEventById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/life-event/get-life-event-details/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

// fetch all life events

export const fetchAllLifeEvents = async (empId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/life-event/get-all-life-event?id=${empId}&pageSize=5&pageIndex=0`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};
