import { PencilSquareIcon } from '@heroicons/react/24/outline';
import EyeIcon from 'assets/svg/eyeIcon';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import AvatarWithUsernameAndDescription from 'components/staticComponents/AvatarWithUsernameAndDescription/AvatarWithUsernameAndDescription';
import { setIsEditable } from 'store/reducers/profileSlice';

export const UsersTable = ({
  eligibleUsers,
  loading,
  selectedOperation,
  setViewDetails,
  setSelectedUserId,
}) => {
  const tableHeaders = [
    {
      id: '1',
      width: '80%',
      Header: (
        <TableHeader
          name="Employee Details"
          HeaderKey="employeeDetails"
          sort={true}
        />
      ),
      Cell: ({ row }) => (
        <AvatarWithUsernameAndDescription
          userData={row?.original}
          descriptionText={`Emp ID: ${row.original?.employeeId?.value}`}
        />
      ),
    },
    {
      id: '2',
      Header: (
        <TableHeader name="Enrol Status" HeaderKey="enrolStatus" sort={true} />
      ),
      Cell: ({ row }) => (
        <div className="flex items-center rounded-full py-3 gap-1">
          <ChipsLabels
            status={row.original?.enrollmentDetails ? 'Enrolled' : 'Unenrolled'}
            label={row.original?.enrollmentDetails ? 'Enrolled' : 'Unenrolled'}
            className="text-sm font-medium"
          />
        </div>
      ),
    },
    {
      id: '3',
      width: selectedOperation === 'enrolment' ? '8%' : '5%',
      Header: <TableHeader name="Actions" HeaderKey="actions" sort={true} />,
      Cell: ({ row }) => (
        <div
          className={`flex items-center ${
            selectedOperation === 'claim' ? 'justify-center' : ''
          } rounded-full py-3 gap-6`}
        >
          {selectedOperation === 'enrolment' && (
            <div
              className={`${
                row.original?.enrollmentDetails ? '' : 'collapse'
              } cursor-pointer`}
              onClick={() => setViewDetails(true)}
            >
              <EyeIcon />
            </div>
          )}
          <PencilSquareIcon
            className="w-5 h-5 cursor-pointer"
            onClick={() => {
              setViewDetails(true);
              setIsEditable(true);
              setSelectedUserId(row.original?.userId?.value);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <TableSkeleton count={5} />
      ) : (
        <>
          <Table
            data={eligibleUsers}
            columns={
              selectedOperation === 'enrolment'
                ? tableHeaders
                : tableHeaders?.filter((header) => header?.id !== '2')
            }
          />
          {eligibleUsers?.length === 0 && (
            <p className="font-semibold text-sm text-gray-400 -mt-4 flex items-center justify-center">
              No eligible users
            </p>
          )}
        </>
      )}
    </div>
  );
};
