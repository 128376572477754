import { TrashIcon } from '@heroicons/react/24/outline';
import MoveIcon from 'assets/svg/moveIcon';
import Chart from 'components/molecules/chart/ChartComponent';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import HeatmapChart from 'components/molecules/nivo/charts/HeatMapChart';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import RenderDashboardCharts from 'pages/dashboard/dashboardCharts/RenderDashboardCharts';
import { getChartTable } from 'apiClient/dashboardBuilder/DashboardBuilder';
import CircularLoaderSmall from 'assets/svg/CircularLoaderSmall';
import { set } from 'lodash';
import GraphLoader from 'components/loaders/GraphLoader';

const DashboardContent = ({
  dashboardSettings,
  layout,
  filters,
  dashboardRef,
  isDownload,
  completeRefresh,
}) => {
  const [layoutData, setLayoutData] = useState([]);
  const [selectedChart, setSelectedChart] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [chartData, setChartData] = useState({});
  const [errorStates, setErrorStates] = useState({});
  const dashboardId = dashboardSettings?.id;

  const getFormattedData = (data) => {
    return data?.map((card) => {
      return {
        i: card?.data?.i,
        x: card?.data?.x,
        y: card?.data?.y,
        w: card?.data?.w,
        h: card?.data?.h,
        id: card?.id,
        moved: false,
        static: false,
        cardData: {
          name: card?.name,
          description: card?.description,
          type: card?.data?.type,
        },
      };
    });
  };

  useEffect(() => {
    setLayoutData(getFormattedData(layout?.charts));
  }, [layout]);

  useEffect(() => {
    const fetchDataForCharts = async () => {
      if (!layoutData || layoutData?.length === 0) return;
      setLoadingStates((prev) => {
        const newLoadingStates = {};
        layoutData?.forEach((card) => {
          newLoadingStates[card?.id] = true;
        });
        return { ...prev, ...newLoadingStates };
      });

      try {
        const chartDataResponses = await Promise.all(
          layoutData?.map(async (card) => {
            try {
              const response = await getChartTable(card?.id, dashboardId, {
                filters: filters,
              });
              return { chartId: card?.id, datasets: response, error: null };
            } catch (error) {
              console.error(error);
              return { chartId: card?.id, datasets: null, error: error };
            }
          }),
        );

        const newChartData = {};
        const newErrorStates = {};

        chartDataResponses?.forEach(({ chartId, datasets, error }) => {
          if (error) {
            newErrorStates[chartId] = true; // Set error for the specific chart
          } else {
            newChartData[chartId] = datasets;
            newErrorStates[chartId] = false; // No error for the specific chart
          }
        });

        setChartData(newChartData);
        setErrorStates((prev) => ({ ...prev, ...newErrorStates }));
      } finally {
        setLoadingStates((prev) => {
          const newLoadingStates = {};
          Object.keys(prev)?.forEach((key) => {
            newLoadingStates[key] = false;
          });
          return { ...prev, ...newLoadingStates };
        });
      }
    };

    fetchDataForCharts();
  }, [layoutData, filters, completeRefresh]);

  const renderCards = () => {
    return layoutData?.map((card, index) => {
      const cardChartData = chartData[card?.id];
      const isLoading = loadingStates[card?.id];
      const hasError = errorStates[card?.id];
      if (card?.data?.type === 'separator') {
        return (
          <div
            key={card?.i}
            className={`rounded h-full flex flex-col justify-between w-full p-2 `}
          >
            {/* <div className="flex items-center gap-2.5 self-end pb-2">
                <div className="drag-handle cursor-move">
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div> */}
            <div className=" bg-black h-1 w-full"></div>
          </div>
        );
      } else if (card?.data?.type === 'text') {
        return (
          <div
            key={card?.i}
            className={`rounded flex h-full w-full flex-col justify-between p-2 min-w-24 `}
          >
            <div
              className="break-words text-wrap"
              style={{
                color: card?.data?.fontColor ? card?.data?.fontColor : 'black',
                fontSize: card?.data?.fontSize
                  ? `${card?.data?.fontSize}px`
                  : '16px',
                fontFamily: card?.data?.fontFamily
                  ? card?.data?.fontFamily
                  : 'Arial',
              }}
            >
              {card?.data?.description || 'Edit Text'}
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={card?.i}
            className={`flex flex-col bg-white shadow rounded min-h-60 min-w-52`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.cardData?.name}
              </span>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.cardData?.description}
            </span>
            {isLoading ? (
              <GraphLoader />
            ) : hasError ? (
              <div className="h-full p-4 w-full inline-block text-red-500 text-center">
                No Table Available For This Chart
              </div>
            ) : (
              cardChartData && (
                <div className="h-full p-4 w-full inline-block">
                  <RenderDashboardCharts
                    displayLegend={true}
                    chartType={card?.cardData?.type}
                    datasets={cardChartData}
                  />
                </div>
              )
            )}
          </div>
        );
      }
    });
  };

  return (
    <div className="flex flex-col gap-1 px-4">
      <div
        style={{
          backgroundColor: dashboardSettings?.settings?.backgroundColor,
        }}
        className={`min-h-full h-max px-6 py-6`}
        ref={dashboardRef}
      >
        <div
          className={`bg-white text-gray-900 border text-xl font-semibold py-2 rounded flex border-gray-400 w-full items-center justify-center`}
        >
          {layout?.name}
        </div>
        <GridLayout
          className="layout"
          layout={layoutData}
          cols={12}
          rowHeight={20}
          margin={[
            dashboardSettings?.settings?.spacing || 8,
            dashboardSettings?.settings?.spacing || 8,
          ]}
          width={document.documentElement.clientWidth - 211}
          height="100%"
          draggableHandle=".drag-handle"
          resizeHandles={[]}
          autoSize={true}
        >
          {renderCards()}
        </GridLayout>
      </div>
    </div>
  );
};

export default DashboardContent;
