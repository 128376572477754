import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function InfiniteLoading({ data, fetchData }) {
  //data => array
  //fetchData => api call to load next set of data, it should update your page number and useEffect should call the data
  // don't forget to append the data to the exisitng array

  return (
    <InfiniteScroll
      dataLength={data?.length} //This is important field to render the next data ==> array.length
      next={fetchData}
      hasMore={true}
      loader={<h4>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      // below props only if you need pull down functionality
      //   refreshFunction={this.refresh}
      //   pullDownToRefresh
      //   pullDownToRefreshThreshold={50}
      //   pullDownToRefreshContent={
      //     <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
      //   }
      //   releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
    >
      {data.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </InfiniteScroll>
  );
}
