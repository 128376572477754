import React from 'react';

const GraphLoader = () => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex animate-pulse gap-2 items-baseline h-full w-full">
      <div className="w-10 h-1/2 bg-gray-300 rounded mb-2"></div>
      <div className="w-10 h-2/3 bg-gray-300 rounded mb-2"></div>
      <div className="w-10 h-3/4 bg-gray-300 rounded mb-2"></div>
      <div className="w-10 h-full bg-gray-300 rounded"></div>
      <div className="w-10 h-full bg-gray-300 rounded"></div>
      <div className="w-10 h-full bg-gray-300 rounded"></div>
    </div>
  );
};

export default GraphLoader;
