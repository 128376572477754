import React from 'react';
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap';
import { fontSize, fontWeight } from 'config/templateBuilderConfig';

const HeatmapChart = ({ data, keys, displayLegend }) => {
  if (!data || !Array.isArray(data)) {
    console.error('Invalid data format:', data);
    return <div>Error: Invalid data format</div>;
  }

  if (!keys || !Array.isArray(keys)) {
    console.error('Invalid keys format:', keys);
    return <div>Error: Invalid keys format</div>;
  }

  if (typeof displayLegend === 'undefined') {
    displayLegend = true;
  }

  if (data?.length > 0 && keys?.length > 0) {
    const commonProps = {
      data,
      valueFormat: '>-.2s',
      colors: {
        scheme: 'yellow_green',
        type: 'diverging',
        diverging: true,
        divergeAt: 0.5,
      },
      indexBy: 'id',
      margin: { top: 0, right: 90, bottom: 25, left: 50 },
      labelTextColor: { from: 'color', modifiers: [['darker', 3]] },
      axisTop: null,
      axisBottom: {
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      },
      axisLeft: {
        orient: 'left',
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (value) =>
          value.length > 4 ? `${value.substring(0, 4)}...` : value,
      },
      cellOpacity: 1,
      cellBorderColor: { from: 'color', modifiers: [['darker', 0.4]] },
      defs: [
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(0, 0, 0, 0.1)',
          rotation: -45,
          lineWidth: 4,
          spacing: 7,
        },
      ],
      fill: [{ id: 'lines' }],
      animate: true,
      motionStiffness: 80,
      motionDamping: 9,
      hoverTarget: 'cell',
      cellHoverOthersOpacity: 0.25,
      theme: {
        axis: {
          ticks: {
            text: {
              fontSize: 12,
              fontWeight: 500,
              fontFamily: 'Figtree',
            },
          },
        },
        labels: {
          text: {
            fontWeight: 700, // Bold font weight
            fontSize: 13,
          },
        },
        legends: {
          text: {
            fontSize: 16, // Adjust legend font size
            fontWeight: 500,
            fontFamily: 'Figtree', // Set the desired font family
          },
        },
      },
    };

    return (
      <ResponsiveHeatMapCanvas
        {...commonProps}
        {...(displayLegend && {
          legends: [
            {
              anchor: 'right',
              translateX: 30,
              translateY: 0,
              length: 200, // Adjust the length of the legend bar
              direction: 'column',
              justify: false,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              // tickFormat: '>-.2s',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ],
        })}
      />
    );
  } else {
    return <div>Error getting data</div>;
  }
};

export default HeatmapChart;
