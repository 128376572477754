import {
  ArrowDownTrayIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  getCandidateOfferDetails,
  getCandidateOfferTemplate,
  offerAction,
  uploadOfferLetter,
} from 'apiClient/hiringOffer/hiringOffer';
import ToastNotify from 'pages/surface/ToastNotify';
import { toast } from 'react-toastify';
import AcceptTickIcon from 'assets/svg/acceptTickIcon';
import ArrowLeftIcon from 'assets/svg/arrowLeft';
import ButtonLoader from 'assets/svg/buttonLoader';
import EyeIcon from 'assets/svg/eyeIcon';
import { AxiosError } from 'axios';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import Button from 'components/atoms/button/Button';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import PdfViewer from 'components/atoms/pdfViewer/PDFViewer';
import Chart from 'components/molecules/chart/ChartComponent';
import moment from 'moment';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import SelectedFile from 'pages/policy/create/mainPanel/policySteps/SelectedFile';
import ProjectData from 'pages/profile/profileModules/compensation/ProjectData';
import TableRowSection from 'pages/profile/profileModules/compensation/TableRowSection';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import ReviewHeaders from './ReviewHeaders';
import ReviewCompensationData from './ReviewCompensationData';
import LoginWithOTP from './LoginWithOTP';
import LoadingCircle from 'components/molecules/loadingCircle/LoadingCircle';
import {
  calculateGrantAmounts,
  calculateGrowth,
  calculateUnvestedAmounts,
  calculateValue,
  createGradient,
  extractYearPercents,
  sumArrays,
} from './reviewUtils';
import HistoryIconGradient from 'assets/svg/historyIconGradient';
import InfiniteLoading from 'components/molecules/InfiniteLoading/InfiniteLoading';
const initialData = {
  firstYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  secondYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  thirdYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  fourthYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
};
const ltiGrant = {
  amountofYear: 0,
  firstYearPercent: 0,
  secondYearPercent: 0,
  thirdYearPercent: 0,
  fourthYearPercent: 0,
};
const DownloadTemplate = ({ previewLink }) => {
  const handleDownloadTemplate = () => {
    const a = document.createElement('a');
    a.href = previewLink;
    a.download = `offerLetter.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div
      className="flex gap-2 items-center border rounded-lg p-2 justify-center cursor-pointer border-gray-400 text-gray-600"
      onClick={() => handleDownloadTemplate()}
    >
      <ArrowDownTrayIcon className="w-5 h-5 " /> <p>Download Offer Letter</p>
    </div>
  );
};
const ReviewOffer = () => {
  const numberOfYears = 4;
  const PERCENT_DECIMAL = 1;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let value = queryParams.get('value');
  let email = null;
  if (value && value.includes('?email=')) {
    const [actualValue, emailParam] = value.split('?email=');
    value = actualValue;
    email = emailParam;
  } else {
    email = queryParams.get('email');
  }
  const storedOrgDetails = localStorage.getItem('orgDetails');
  const parsedOrgDetails = storedOrgDetails
    ? JSON.parse(storedOrgDetails)
    : null;
  const [showExpirePopup, setShowExpirePopup] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [commentMessage, setCommentMessage] = useState('');
  const [vestingSchedule, setVestingSchedule] = useState({});
  const [releaseData, setReleaseData] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [previewLink, setPreviewLink] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [offerStatus, setOfferStatus] = useState();
  const [previewLoading, setPreviewLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [inputFileData, setInputFileData] = useState();
  const [offerDetailsData, setOfferDetailsData] = useState();
  const [acceptPopup, setAcceptPopup] = useState(false);
  const [compDataList, setCompDataList] = useState([]);
  const [totalCompensationValue, setTotalCompensationValue] = useState(0);
  const [realizedWealth, setRealizedWealth] = useState(false);
  const [yearData, setYearData] = useState(initialData);
  const [grantsArray, setGrantsArray] = useState([0, 0, 0, 0]);
  const methods = useForm({
    defaultValues: {
      showAmountInMillions: false,
      allowLtiGrants: false,
      allowOneTimePayment: false,
      simulationProject: {
        benefitsPay: '',
        rangeSlider: 0,
        benefitsPayPercentage: '',
        currentBasePay: '',
        currentBasePayPercentage: '',
        variablePay: '',
        currentUnvestedValue: 0,
        variablePayPercentage: '',
        currentUnvested: {
          firstYearPercent: 0,
          fourthYearPercent: 0,
          secondYearPercent: 0,
          thirdYearPercent: 0,
        },
        ltiGrants: [{ ...ltiGrant }],
      },
    },
    shouldFocusError: false,
  });
  const { control, setValue, watch } = methods;
  const simulationProject = useWatch({
    control,
    name: 'reviewOffer',
  });
  const handleResponse = (message) => {
    toast(<ToastNotify message={message} close={() => {}} />, {
      onClose: () => {},
      autoClose: 2000,
      className:
        'toast-message absolute w-full h-full py-4 px-[30px] bg-white gap-4 flex flex-col border border-gray-200 shadow-md rounded-lg',
      zIndex: 1000,
    });
  };
  const [showAmountInMillions, allowLtiGrants, allowOneTimePayment] = watch([
    'showAmountInMillions',
    'allowLtiGrants',
    'allowOneTimePayment',
  ]);
  const candidateAccess = localStorage?.getItem('candidateAccess');
  const accessType = localStorage?.getItem('accessType');
  const currentYear = new Date().getFullYear();
  const chartLabels = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear + index,
  );
  const fetchCandidateOfferDetails = async (body) => {
    try {
      const data = await getCandidateOfferDetails(body);
      return data;
    } catch (err) {
      throw new AxiosError(err?.message);
    }
  };
  const firstYearSum = Math.ceil(
    (yearData.firstYear?.basePay || 0) +
      (yearData.firstYear?.variablePay || 0) +
      (yearData.firstYear?.benefitsPay || 0) +
      (allowLtiGrants ? grantsArray[0] || 0 : 0) +
      (allowOneTimePayment
        ? parseInt(simulationProject?.oneTimePayYear1 || 0)
        : 0),
  );
  const secondYearSum = Math.ceil(
    (yearData.secondYear?.basePay || 0) +
      (yearData.secondYear?.variablePay || 0) +
      (yearData.secondYear?.benefitsPay || 0) +
      (allowLtiGrants ? grantsArray[1] || 0 : 0) +
      (allowOneTimePayment
        ? parseInt(simulationProject?.oneTimePayYear2 || 0)
        : 0),
  );
  const thirdYearSum = Math.ceil(
    (yearData.thirdYear?.basePay || 0) +
      (yearData.thirdYear?.variablePay || 0) +
      (yearData.thirdYear?.benefitsPay || 0) +
      (allowLtiGrants ? grantsArray[2] || 0 : 0),
  );
  const fourthYearSum = Math.ceil(
    (yearData.fourthYear?.basePay || 0) +
      (yearData.fourthYear?.variablePay || 0) +
      (yearData.fourthYear?.benefitsPay || 0) +
      (allowLtiGrants ? grantsArray[3] || 0 : 0),
  );
  useEffect(() => {
    const fyBasePay = calculateValue(
      simulationProject?.currentBasePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const fyVariablePay = calculateValue(
      simulationProject?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const fyBenefitsPay = calculateValue(
      simulationProject?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      firstYear: {
        ...prevData.firstYear,
        basePay: fyBasePay,
        variablePay: fyVariablePay,
        benefitsPay: fyBenefitsPay,
      },
    }));
  }, [simulationProject]);

  useEffect(() => {
    const syBasePay = calculateValue(
      yearData.firstYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const syVariablePay = calculateValue(
      yearData.firstYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const syBenefitsPay = calculateValue(
      yearData.firstYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      secondYear: {
        ...prevData.secondYear,
        basePay: syBasePay,
        variablePay: syVariablePay,
        benefitsPay: syBenefitsPay,
      },
    }));
  }, [yearData.firstYear]);
  useEffect(() => {
    const tyBasePay = calculateValue(
      yearData.secondYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const tyVariablePay = calculateValue(
      yearData.secondYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const tyBenefitsPay = calculateValue(
      yearData.secondYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      thirdYear: {
        ...prevData.thirdYear,
        basePay: tyBasePay,
        variablePay: tyVariablePay,
        benefitsPay: tyBenefitsPay,
      },
    }));
  }, [yearData.secondYear]);
  useEffect(() => {
    const fourthBasePay = calculateValue(
      yearData.thirdYear?.basePay,
      simulationProject?.currentBasePayPercentage || 0,
    );
    const fourthVariablePay = calculateValue(
      yearData.thirdYear?.variablePay,
      simulationProject?.variablePayPercentage || 0,
    );
    const fourthBenefitsPay = calculateValue(
      yearData.thirdYear?.benefitsPay,
      simulationProject?.benefitsPayPercentage || 0,
    );
    setYearData((prevData) => ({
      ...prevData,
      fourthYear: {
        ...prevData.fourthYear,
        basePay: fourthBasePay,
        variablePay: fourthVariablePay,
        benefitsPay: fourthBenefitsPay,
      },
    }));
  }, [yearData.thirdYear]);

  useEffect(() => {
    const averageGrowth = simulationProject?.rangeSlider;
    const unvestedGrowthArray = calculateGrowth(
      simulationProject?.currentUnvestedValue,
      averageGrowth,
    );
    const unvestedPercents = [
      simulationProject?.currentUnvested?.firstYearPercent,
      simulationProject?.currentUnvested?.secondYearPercent,
      simulationProject?.currentUnvested?.thirdYearPercent,
      simulationProject?.currentUnvested?.fourthYearPercent,
    ];
    const unvestedAmounts = calculateUnvestedAmounts(
      unvestedGrowthArray,
      unvestedPercents,
    );
    const totalsArray = simulationProject?.ltiGrants?.reduce(
      (acc, item, index) => {
        const grantGrowthArray = calculateGrowth(
          item?.amountofYear,
          averageGrowth,
        );
        const grantAmounts = calculateGrantAmounts(
          item,
          grantGrowthArray,
          index,
        );
        return sumArrays(acc, grantAmounts);
      },
      [0, 0, 0, 0],
    );
    const updatedTotalsArray = sumArrays(totalsArray, unvestedAmounts);
    setGrantsArray(updatedTotalsArray);
  }, [
    simulationProject?.ltiGrants,
    simulationProject?.rangeSlider,
    simulationProject?.currentUnvestedValue,
    simulationProject?.currentUnvested,
  ]);
  useEffect(() => {
    if (candidateAccess) {
      setDataLoading(true);
      const payload = {
        value: candidateAccess,
      };
      fetchCandidateOfferDetails(payload)
        .then((res) => {
          setOfferDetailsData(res?.data);
        })
        .catch((error) => {
          if (error?.message?.includes('Session expired')) {
            setShowExpirePopup(true);
          }
          handleResponse(error?.message);
        })
        .finally(() => setDataLoading(false));
    }
  }, [candidateAccess]);
  useEffect(() => {
    if (offerDetailsData) {
      let totalValue = 0;
      const newCompData = [];
      if (offerDetailsData?.hiringProposal?.[0]?.componsationProposal?.body) {
        Object?.entries(
          offerDetailsData.hiringProposal[0].componsationProposal.body,
        )?.forEach(([key, item]) => {
          if (typeof item === 'object' && item?.total) {
            const headerData = offerDetailsData?.compSideNavDetails[key];
            const foundObject =
              offerDetailsData?.compSideNavDetails?.compFields?.find(
                (item) => item?.codeId === key,
              );
            if (foundObject?.name === 'LTI') {
              setVestingSchedule(item?.vestingSchedule);
            }
            totalValue += parseInt(item?.total);
            newCompData.push({ ...item, ...foundObject, header: headerData });
          }
        });
      }
      if (offerDetailsData?.hiringProposal?.[0]?.hiringReleaseLetter) {
        const releaseLetterData =
          offerDetailsData?.hiringProposal?.[0]?.hiringReleaseLetter?.[0];
        setReleaseData(releaseLetterData);
        // if (releaseLetterData?.signatureLetterUrl === null) {
        //   setPreviewLink(imageURLCheck(releaseLetterData?.releaseLetterUrl));
        // } else {
        //   setPreviewLink(imageURLCheck(releaseLetterData?.signatureLetterUrl));
        // }
      }
      setTotalCompensationValue(totalValue);
      setCompDataList(newCompData);
    }
  }, [offerDetailsData]);
  useEffect(() => {
    if (compDataList && compDataList?.length > 0) {
      compDataList?.map((item) => {
        if (item?.name === 'Fixed Salary') {
          methods?.setValue(
            `reviewOffer.currentBasePay`,
            parseInt(item?.total),
          );
          methods?.setValue(`reviewOffer.currentBasePayPercentage`, 1);
        } else if (item?.name === 'Benefits') {
          methods?.setValue(`reviewOffer.benefitsPay`, parseInt(item?.total));
          methods?.setValue(`reviewOffer.benefitsPayPercentage`, 1);
        } else if (item?.name === 'Variable') {
          methods?.setValue(`reviewOffer.variablePay`, parseInt(item?.total));
          methods?.setValue(`reviewOffer.variablePayPercentage`, 1);
        } else if (item?.name === 'One time Payout') {
          methods?.setValue(
            `reviewOffer.oneTimePayYear1`,
            parseInt(item?.total),
          );
          methods?.setValue(
            `reviewOffer.oneTimePayYear2`,
            parseInt(item?.total),
          );
        } else if (item?.name === 'LTI') {
          methods?.setValue(
            `reviewOffer.currentUnvestedValue`,
            parseInt(item?.total),
          );
          methods?.setValue(`reviewOffer.variablePayPercentage`, 1);
        }
      });
    }
  }, [compDataList]);
  useEffect(() => {
    if (vestingSchedule?.name) {
      const {
        firstYearPercent,
        secondYearPercent,
        thirdYearPercent,
        fourthYearPercent,
      } = extractYearPercents(vestingSchedule?.name);
      methods?.setValue(
        `reviewOffer.currentUnvested.firstYearPercent`,
        firstYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.secondYearPercent`,
        secondYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.thirdYearPercent`,
        thirdYearPercent,
      );
      methods?.setValue(
        `reviewOffer.currentUnvested.fourthYearPercent`,
        fourthYearPercent,
      );
    }
  }, [vestingSchedule]);

  const simulationData = [
    firstYearSum,
    secondYearSum,
    thirdYearSum,
    fourthYearSum,
  ];
  const dataForChart = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Bar Chart',
        backgroundColor: createGradient(),
        data: simulationData,
        fill: false,
        tension: 0.1,
      },
    ],
  };
  const chartOptions = {};
  const handleAcceptOffer = (type) => {
    const body = {
      value: candidateAccess,
      status: type,
    };
    offerAction(body)
      ?.then((res) => {
        handleResponse('Response Saved Successfully');
        setOfferStatus(type);
      })
      .catch((error) => {
        if (error?.message?.includes('Session expired')) {
          setShowExpirePopup(true);
        }
        handleResponse(error?.message);
      });
  };
  const handleSubmitAcceptance = () => {
    if (inputFileData) {
      const reqData = new FormData();
      reqData.append('signOfferLetter', inputFileData);
      reqData.append('value', candidateAccess);
      reqData.append('comment', commentMessage);
      uploadOfferLetter(reqData)
        ?.then((res) => {
          setAcceptPopup(false);
          handleResponse('Offer Accepted Successfully');
          setOfferStatus('Accept');
        })
        .catch((error) => {
          if (error?.message?.includes('Session expired')) {
            setShowExpirePopup(true);
          }
          handleResponse(error?.message);
        });
    }
  };

  const handlePreviewPDF = async () => {
    try {
      setPreviewLoading(true);
      const res = await getCandidateOfferTemplate({ value: candidateAccess });
      if (!res.ok) {
        console.error('Network response was not ok');
      }
      const blob = await res?.blob();
      const url = URL.createObjectURL(blob);
      setPreviewLink(url);
    } catch (err) {
      if (err?.message?.includes('Session expired')) {
        setShowExpirePopup(true);
      }
      handleResponse(err?.message || err);
    } finally {
      setPreviewLoading(false);
    }
  };
  useEffect(() => {
    handlePreviewPDF();
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        {acceptPopup && (
          <ModalDialogue
            icon={<AcceptTickIcon />}
            isModalOpen={acceptPopup}
            onClose={() => setAcceptPopup(false)}
            title={'Accept Offer'}
            description={'Upload Signed Offer Letter/Redirect to DocuSign'}
            width="500px"
          >
            <div className="flex flex-col gap-4 w-full py-4">
              <DownloadTemplate value={previewLink} />
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">
                  Upload signed offer letter
                </p>
                {inputFileData ? (
                  <>
                    <SelectedFile
                      file={inputFileData}
                      onRemoveImage={() => {
                        setInputFileData(null);
                      }}
                    />
                  </>
                ) : (
                  <FileUploader
                    acceptedTypes={['.pdf']}
                    maxSize={5 * 1024 * 1024}
                    subText="PDF (Max. 5 MB)"
                    onFileSelect={(file) => {
                      console.log(file);
                      setInputFileData(file);
                    }}
                  />
                )}
              </div>{' '}
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Comments</p>
                <TextBox
                  className="!mt-0 !py-2  border border-gray-300"
                  placeholder="Comments.."
                  value={commentMessage}
                  rows="3"
                  onChange={(e) => setCommentMessage(e.target.value)}
                />
              </div>
              <div className="flex gap-4 justify-end mt-5">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAcceptPopup(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="filled"
                  onClick={() => handleSubmitAcceptance()}
                  disabled={!inputFileData || approveLoading}
                >
                  {approveLoading ? (
                    <span>
                      <ButtonLoader /> Accepting..
                    </span>
                  ) : (
                    'Submit Acceptance'
                  )}
                </Button>
              </div>
            </div>
          </ModalDialogue>
        )}
        {showExpirePopup && offerDetailsData && (
          <>
            {' '}
            <ModalDialogue
              icon={<HistoryIconGradient />}
              isModalOpen={showExpirePopup}
              onClose={() => setShowExpirePopup(false)}
              title={'Session Expired!'}
              description={'Please login again to continue.'}
              width="500px"
            >
              <div className="flex gap-4 justify-end mt-5">
                <Button
                  variant="filled"
                  onClick={() => {
                    setShowLogin(true);
                    localStorage?.removeItem('candidateAccess');
                    localStorage?.removeItem('accessType');
                  }}
                >
                  Log In
                </Button>
              </div>
            </ModalDialogue>
          </>
        )}
        {showPopUp && (
          <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto custom-scrollbar w-full pb-8">
            <div className="flex flex-col items-center justify-center gap-4 min-h-screen mx-auto pt-24 w-full">
              <div className="flex items-center justify-between w-9/12 mx-auto">
                <div className="flex items-center gap-4 text-white">
                  <ArrowLeftIcon
                    className="w-5 h-5 cursor-pointer text-white"
                    stroke="white"
                    onClick={() => {
                      setShowPopUp(false);
                    }}
                  />
                </div>
              </div>
              <div
                className={`bg-white rounded-lg shadow-md w-9/12  p-4  pb-10`}
              >
                {previewLoading ? (
                  <div className="w-full flex items-center justify-center">
                    <LoadingCircle />
                  </div>
                ) : (
                  <div className="w-full mx-auto">
                    {previewLink === undefined || !previewLink ? (
                      <div className="text-gray-600 flex items-center justify-center">
                        Unable to preview document.
                      </div>
                    ) : (
                      <>
                        <iframe
                          src={previewLink}
                          title="pdf"
                          width="100%"
                          className="rounded-lg"
                          height="600px"
                          scrolling="no"
                          // sandbox="allow-same-origin allow-scripts allow-popups"
                        ></iframe>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {showLogin && !localStorage.getItem('candidateAccess') ? (
          <>
            <LoginWithOTP
              orgDetails={parsedOrgDetails}
              setShowLogin={setShowLogin}
              value={value}
              email={email}
              handleResponse={handleResponse}
            />
          </>
        ) : dataLoading ? (
          <div className="w-screen h-screen flex items-center justify-center">
            <InfiniteLoading />
          </div>
        ) : offerDetailsData ? (
          <div className="flex flex-col w-full">
            <ReviewHeaders
              offerDetailsData={offerDetailsData}
              parsedOrgDetails={parsedOrgDetails}
              totalCompensationValue={totalCompensationValue}
              compDataList={compDataList}
            />
            <div className="flex w-full">
              <ReviewCompensationData compDataList={compDataList} />
              <div className="bg-white w-8/12 p-4 flex flex-col gap-4">
                <div className="flex items-center justify-between border rounded-lg shadow-md p-4">
                  <div className="flex items-center gap-2">
                    <p className="font-semibold text-lg">Your Response</p>
                    {releaseData &&
                      (releaseData?.status === 'Accept' ||
                      offerStatus === 'Accept' ? (
                        <div className="rounded-lg py-0.5 px-2 text-[13px] w-auto text-[#039855] bg-[#E3FCEC] ">
                          Accepted
                        </div>
                      ) : releaseData?.status === 'Reject' ||
                        offerStatus === 'Reject' ? (
                        <div className="rounded-lg py-0.5 px-2 text-[13px] w-auto text-[#D92D20] bg-[#FEF3F2] ">
                          Rejected
                        </div>
                      ) : (
                        <></>
                      ))}
                  </div>
                  <div className="flex gap-2">
                    <div
                      className="flex items-center gap-2 p-2 rounded-lg border border-gray-400 text-gray-600"
                      onClick={() => {
                        setShowPopUp(true);
                        // handlePreviewPDF();
                      }}
                    >
                      <EyeIcon className="w-4 h-4" /> View Offer Letter
                    </div>
                    {accessType !== 'editOffer' ? (
                      <>
                        <DownloadTemplate
                          previewLink={previewLink}
                          // handlePreviewPDF={handlePreviewPDF}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className="flex items-center gap-2 p-2 rounded-lg border border-green text-green cursor-pointer"
                          onClick={() => setAcceptPopup(true)}
                        >
                          <CheckIcon className="w-5 h-5 text-green" /> Accept
                        </div>{' '}
                        <div
                          className="flex items-center gap-2 p-2 rounded-lg border border-red-600 text-red-600"
                          onClick={() => handleAcceptOffer('Reject')}
                        >
                          <XMarkIcon className="w-5 h-5 text-red-600" /> Reject
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <p className="font-semibold text-xl">Simulate Growth</p>
                <div className="bg-gray-100 rounded-lg p-4 flex flex-col gap-2">
                  <div className="flex flex-col">
                    <p className="font-semibold text-lg">Description</p>
                    <p className="text-gray-700">
                      Simulate your growth with the company and project your
                      income on the basis of your assumption.
                    </p>
                  </div>{' '}
                  <div className="flex flex-col">
                    <p className="font-semibold text-lg">Disclaimer</p>
                    <p className="text-gray-700">
                      The simulation is purely based on your assumption. It will
                      have no impact on the actual value and growth.
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 w-full">
                  <div className="w-2/6 p-4 border rounded-lg bg-gray-100">
                    {' '}
                    <ProjectData
                      projectTitle={parsedOrgDetails?.orgName}
                      project={'reviewOffer'}
                      // ltiGrant={ltiGrant}
                    />
                  </div>
                  <div className="w-4/6 rounded-lg shadow-md bg-white p-4 flex flex-col gap-4">
                    {' '}
                    <div className="flex items-center justify-between gap-4">
                      {/* <p className="font-medium text-gray-600 text-sm">The Values are shown in Lac(s)</p> */}
                      <p className="font-medium text-gray-600 text-sm"></p>
                      <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-700">
                          {' '}
                          Lacs{' '}
                        </span>
                        <Toggle
                          checked={showAmountInMillions}
                          onChange={() => {
                            setValue(
                              'showAmountInMillions',
                              !showAmountInMillions,
                            );
                          }}
                        />{' '}
                        <span className="text-sm font-medium text-gray-700">
                          {' '}
                          Millions{' '}
                        </span>
                      </div>
                    </div>
                    <Chart
                      type="bar"
                      data={dataForChart}
                      chartOptions={chartOptions}
                    />
                    <div className="flex flex-col gap-2 w-full">
                      <div className="flex gap-2">
                        <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-gray-900 text-sm font-semibold">
                          Comparison
                        </div>
                        <div className="w-8/12 p-2 rounded-lg bg-gray-100 border border-gray-300 grid grid-cols-4 gap-2">
                          {chartLabels.map((year) => (
                            <div
                              className=" flex items-center justify-center text-sm text-gray-900 font-semibold"
                              key={year}
                            >
                              {year}
                            </div>
                          ))}
                        </div>
                        <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-sm text-gray-900 font-semibold">
                          Company
                        </div>
                      </div>
                      <>
                        <TableRowSection
                          title="Base"
                          data={[
                            yearData.firstYear?.basePay || 0,
                            yearData.secondYear?.basePay || 0,
                            yearData.thirdYear?.basePay || 0,
                            yearData.fourthYear?.basePay || 0,
                          ]}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                        <TableRowSection
                          title="Variable"
                          data={[
                            yearData.firstYear?.variablePay || 0,
                            yearData.secondYear?.variablePay || 0,
                            yearData.thirdYear?.variablePay || 0,
                            yearData.fourthYear?.variablePay || 0,
                          ]}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                        {allowLtiGrants && (
                          <TableRowSection
                            title="ESOPs"
                            data={grantsArray}
                            selectedSideTab={'Simulation'}
                            simulationTitle="Current"
                            ComparisionTitle="Potential"
                          />
                        )}
                        <TableRowSection
                          title="Benefits"
                          data={[
                            yearData.firstYear?.benefitsPay || 0,
                            yearData.secondYear?.benefitsPay || 0,
                            yearData.thirdYear?.benefitsPay || 0,
                            yearData.fourthYear?.benefitsPay || 0,
                          ]}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                        {allowOneTimePayment && (
                          <TableRowSection
                            title="Onetime"
                            data={[
                              parseInt(simulationProject?.oneTimePayYear1 || 0),
                              parseInt(simulationProject?.oneTimePayYear2 || 0),
                              0,
                              0,
                            ]}
                            selectedSideTab={'Simulation'}
                            simulationTitle="Current"
                            ComparisionTitle="Potential"
                          />
                        )}
                        <TableRowSection
                          title="Total"
                          data={[
                            firstYearSum || 0,
                            secondYearSum || 0,
                            thirdYearSum || 0,
                            fourthYearSum || 0,
                          ]}
                          selectedSideTab={'Simulation'}
                          simulationTitle="Current"
                          ComparisionTitle="Potential"
                        />
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-screen h-screen flex items-center justify-center">
            <div className="flex flex-col gap-3">
              <p className="font-semibold">Unabale to load the data</p>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowLogin(true);
                  localStorage?.removeItem('candidateAccess');
                  localStorage?.removeItem('accessType');
                  window?.location?.reload();
                }}
              >
                Try again
              </Button>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
};

export default ReviewOffer;
