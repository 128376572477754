import { TaskLifeEventApproval } from 'pages/surface/taskLifeEventApproval/TaskLifeEventApproval';
import React from 'react';

const NominationApproval = React.lazy(() =>
  import('pages/surface/nominationApproval/NominationApproval'),
);
const ContentReport = React.lazy(() =>
  import('pages/surface/contentReport/ContentReport'),
);
const Task = React.lazy(() => import('pages/surface/task/Task'));
const TaskPolicyApproval = React.lazy(() =>
  import('pages/surface/taskPolicyApproval/TaskPolicyApproval'),
);
const TaskClaimApproval = React.lazy(() =>
  import('pages/surface/taskClaimApproval/TaskClaimApproval'),
);
const EnrollmentApproval = React.lazy(() =>
  import('pages/surface/enrollmentApproval/EnrollmentApproval'),
);

const UserTaskRoutes = [
  // >>>> Admin and default routes Starts here
  {
    type: 'collapse',
    name: 'user-task-nomination-approval',
    key: 'user-task-nomination-approval',
    route: '/task/nomination-approval/:id',
    parentId: 'Task',
    module: 'Task',
    component: <NominationApproval />,
  },
  {
    type: 'collapse',
    name: 'user-tasks',
    key: 'user-tasks',
    route: '/task/',
    parentId: null,
    module: 'Task',
    component: <Task />,
  },
  {
    type: 'collapse',
    name: 'user-task-policy-approval',
    key: 'user-task-policy-approval',
    route: '/task/policy-approval/:id',
    parentId: 'Task',
    module: 'Task',
    component: <TaskPolicyApproval />,
  },
  {
    type: 'collapse',
    name: 'user-task-life-event-approval',
    key: 'user-task-life-event-approval',
    route: '/task/life-event/:id',
    parentId: 'Task',
    module: 'Task',
    component: <TaskLifeEventApproval />,
  },
  {
    type: 'collapse',
    name: 'user-task-claim-approval',
    key: 'user-task-claim-approval',
    route: '/task/claim-approval/:id',
    parentId: 'Task',
    module: 'Task',
    component: <TaskClaimApproval />,
  },
  {
    type: 'collapse',
    name: 'user-task-enrollment-approval',
    key: 'user-task-enrollment-approval',
    route: '/task/enrollment-approval/:id',
    parentId: 'Task',
    module: 'Task',
    component: <EnrollmentApproval />,
  },
  {
    type: 'collapse',
    name: 'user-task-content-report',
    key: 'user-task-content-report',
    route: '/task/content-report/:id',
    parentId: 'Task',
    module: 'Task',
    component: <ContentReport />,
  },
];

export default UserTaskRoutes;
