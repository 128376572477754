import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import { imageURLCheck } from 'utils/ImageUrlCheck';

export const BenefitFormEmployee = ({ formDetails, setAnswers, answers }) => {
  const handleChange = (key, value) => {
    setAnswers({ ...answers, [key]: value });
  };

  const handleFileInput = (key, file) => {
    const urls = {
      url: URL?.createObjectURL(file),
      type: file.type,
      name: file.name,
      file: file,
    };

    setAnswers({ ...answers, [key]: urls });
  };

  return (
    <div className="space-y-4">
      {formDetails?.map((field) => {
        return (
          <div key={field?.fieldID}>
            {(field?.settings?.dataType === 'Number' ||
              field?.settings?.dataType === 'Text') && (
              <Input
                label={field?.settings?.name}
                type={(field?.settings?.dataType || '').toLowerCase()}
                required={field?.settings?.mandatory}
                onChange={(event) =>
                  handleChange(field?.fieldID, event.target.value)
                }
                value={answers?.[field?.fieldID] || ''}
              />
            )}
            {field?.settings?.dataType === 'Date' && (
              <div>
                <DatePicker
                  label={field?.settings?.name}
                  onChange={(value) =>
                    handleChange(field?.fieldID, new Date(value))
                  }
                  selected={answers?.[field?.fieldID]}
                />
              </div>
            )}
            {field?.settings?.dataType === 'Dropdown' && (
              <Select
                options={
                  field?.settings?.options?.map((option) => {
                    return { ...option, label: option.value };
                  }) || []
                }
                value={field?.settings?.options?.filter(
                  (option) => option?.value === answers?.[field?.fieldID],
                )}
                onChange={(option) =>
                  handleChange(field?.fieldID, option?.value)
                }
              />
            )}
            {field?.settings?.dataType === 'File' && (
              <div>
                <p className="text-sm font-medium leading-5 text-gray-700 my-1 mb-[6px]">
                  {field?.settings?.name}
                  <span className="text-red-600"> *</span>
                </p>
                <div className="w-full mb-6">
                  {answers?.[field?.fieldID]?.url ? (
                    <div>
                      <div className="w-auto h-60 overflow-y-scroll custom-scrollbar">
                        {answers?.[field?.fieldID]?.type !==
                          'application/pdf' && (
                          <img
                            src={
                              answers?.[field?.fieldID]?.url
                                ? imageURLCheck(answers?.[field?.fieldID]?.url)
                                : imageURLCheck(answers?.[field?.fieldID]?.file)
                            }
                            alt="file"
                            className="w-full rounded-lg h-full object-contain"
                          />
                        )}
                      </div>
                      <button
                        className="px-2 py-1 text-sm text-red-500 hover:text-red-700"
                        onClick={() => handleChange(field?.fieldID, null)}
                      >
                        {`Remove - ${answers?.[field?.fieldID]?.name}`}
                      </button>
                    </div>
                  ) : (
                    <FileUploader
                      acceptedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                      maxSize={50 * 1024 * 1024}
                      subText="PNG, JPG, JPEG or PDF (max. 5 MB)"
                      onFileSelect={(file) =>
                        handleFileInput(field?.fieldID, file)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
