import {
  createDashboard,
  createPage,
  getAllFiles,
  getChartData,
  getChartTypes,
  getDimensions,
  getFiltersColumnValues,
  getFilterValues,
  getMeasures,
  getPage,
  getPreviewSampleData,
  getRevertHistory,
  getValidatedData,
  getVersionHistory,
  updateChart,
  updateDashboard,
  updatePage,
  viewDashboardData,
} from 'apiClient/dashboardBuilder/DashboardBuilder';
import { useMutation, useQuery } from 'react-query';

export const useCreateDashboard = (payload) => {
  return useQuery(['create/Dashboard', payload], async () =>
    createDashboard(payload),
  );
};

export const useGetDashboardData = (payload) => {
  return useQuery(['get/DashboardData', payload], async () =>
    viewDashboardData(payload),
  );
};

export const useUpdateDashboardData = (id) =>
  useMutation(
    ['update/DashboardData', id],
    async (payload) => {
      const res = await updateDashboard(payload, id);
      return res;
    },
    {
      enabled: !!id,
    },
  );

export const useCreatePage = (payload) => {
  return useMutation(() => createPage(payload), {
    mutationKey: ['create/Page', payload],
  });
};

export const useUpdatePage = (id) =>
  useMutation(
    ['update/Page', id],
    async (individualPageData) => {
      const res = await updatePage(individualPageData, id);
      return res;
    },
    {
      enabled: !!id,
    },
  );

export const useGetPage = (dashboardId, pageId) => {
  return useQuery(
    ['get/Page', dashboardId, pageId],
    () => getPage(dashboardId, pageId),
    {
      enabled: !!pageId && !!dashboardId,
    },
  );
};
export const useGetPageData = () => {
  return useMutation(
    // ['get/Page', dashboardId, pageId],
    (data) => getPage(data?.dashboardId, data?.pageId),
    //   {
    //     enabled: !!data?.pageId && !!dashboardId,
    //   },
  );
};

export const useGetAllFIles = (id) => {
  return useQuery(['get/AllFiles', id], async () => getAllFiles(id), {
    enabled: !!id,
  });
};

export const useGetChartTypes = (id) => {
  return useQuery(['get/ChartTypes', id], async () => getChartTypes(id), {
    enabled: !!id,
  });
};

export const useGetVersionHistory = (fileId, dashboardId) => {
  return useQuery(
    ['get/VersionHistory', fileId, dashboardId],
    async () => getVersionHistory(fileId, dashboardId),
    {
      enabled: !!fileId && !!dashboardId,
    },
  );
};

export const useGetPreviewSampleData = (fileId, dashboardId) => {
  return useQuery(
    ['get/PreviewTableData', fileId, dashboardId],
    async () => getPreviewSampleData(fileId, dashboardId),
    {
      enabled: !!fileId && !!dashboardId,
    },
  );
};

export const useGetDimensions = (dashboardId) => {
  return useQuery(['get/Dimensions', dashboardId], async () =>
    getDimensions(dashboardId),
  );
};

export const useGetMeasures = (dashboardId) => {
  return useQuery(['get/Measures', dashboardId], async () =>
    getMeasures(dashboardId),
  );
};

export const useGetValidatedData = (fileId, dashboardId) => {
  return useQuery(
    ['validate/File', fileId, dashboardId],
    async (payload) => {
      const res = await getValidatedData(fileId, dashboardId);
      return res;
    },
    {
      enabled: !!fileId && !!dashboardId,
    },
  );
};

export const useGetReversonHistory = (dashboardId, versionId) => {
  return useQuery(
    ['get/revertVersionHistory', dashboardId, versionId],
    async () => getRevertHistory(dashboardId, versionId),
    {
      enabled: !!versionId && !!dashboardId,
    },
  );
};

export const useUpdateChart = (chartId) => {
  return useMutation(
    ['update/Chart', chartId],
    async (payload) => {
      const res = await updateChart(payload, chartId);
      return res;
    },
    {
      enabled: !!chartId,
    },
  );
};

export const useGetChartData = (chartId, dashboardId) => {
  return useQuery(
    ['get/ChartData', chartId, dashboardId],
    async () => getChartData(chartId, dashboardId),
    {
      enabled: !!chartId && !!dashboardId,
    },
  );
};

export const useGetFilterColumnValues = (dashboardId) => {
  return useQuery(
    ['get/FilterColumnValues', dashboardId],
    async () => getFiltersColumnValues(dashboardId),
    {
      enabled: !!dashboardId,
    },
  );
};

export const useGetFilterValues = (columnId) => {
  return useQuery(
    ['get/FilterValues', columnId],
    async () => getFilterValues(columnId),
    {
      enabled: !!columnId,
    },
  );
};
